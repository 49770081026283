import React from "react";
import ReactDOM from "react-dom";

function HelloWorld() {
  return (
    <div>
      <h3>WORKING...</h3>
    </div>
  );
}

ReactDOM.render(<HelloWorld />, document.getElementById("root"));
